
/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

      /* Browser Detection
      ========================================================================== */
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        var isiOS = false;
        var isIE = /*@cc_on!@*/false || !!document.documentMode,
            isEdge = !isIE && !!window.StyleMedia; 
         
        // Check if IE or Edge
        if(isIE || isEdge) {
          $('body').addClass('is-ie');
        }

        var iOSversion = parseFloat(
          ('' + (/CPU.*OS ([0-9_]{1,5})|(CPU like).*AppleWebKit.*Mobile/i.exec(navigator.userAgent) || [0,''])[1])
          .replace('undefined', '3_2').replace('_', '.').replace('_', '')
        ) || false;

        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          isiOS = true;
          $('body').addClass('is-ios');
        }

        if (iOSversion < 10) {
          $('body').addClass('is-not-ios10');
        }

      /* Wow Init
      ========================================================================== */
  		$(window).load(function() {
  			new WOW().init();
  		});

      /* Animsition Init
      ========================================================================== */
      $(document).ready(function() {
        $(".animsition").animsition({
          inClass: 'fade-in',
          outClass: 'fade-out',
          inDuration: 400,
          outDuration: 200,
          // linkElement: '.animsition-link',
          linkElement: 'a:not([target="_blank"]):not([href^="#"])',

          loading: true,
          loadingParentElement: 'body', //animsition wrapper element
          loadingClass: 'page-loading',
          loadingInner: '<img src="https://tech-to-market.com/wp-content/themes/ttm-theme/dist/images/loading-animation-v2.gif" />', // e.g '<img src="loading.svg" />'



          timeout: false,
          timeoutCountdown: 5000,
          onLoadEvent: true,
          browser: [ 'animation-duration', '-webkit-animation-duration'],
          // "browser" option allows you to disable the "animsition" in case the css property in the array is not supported by your browser.
          // The default setting is to disable the "animsition" in a browser that does not support "animation-duration".
          transition: function(url){ window.location.href = url; }
        });
      });

      // >>>>> Page menu
        if($('body').is(".about-us, .single-cases, .page-template-page-meet-the-team, .page-template-page-vacancies")){
          $(window).load(function(){
          // Section menu link html structure
          var item = function(id, title){
            return '<a href="#" class="on-page-menu_link" data-id="' + id + '"><span class="on-page-menu_link_text">' + title + '</span></a>';
          };
          // Loop through sections on page
          $('.section-title').each(function(){
            // Get menu title to populate the html elements
            var menuTitle = $(this).data('menu-title');
            var id = $(this).attr('id');
            // Get current html content of menu
            currentHtml = $('.on-page-menu').find('.inner').html();
            // Update current html content of menu with new item
            $('.on-page-menu').find('.inner').html( currentHtml + item(id, menuTitle) );
          });
          // Place menu on same height of first section
          var offsetTop = 0;
          if ($('.section-title')[0]){
              offsetTop = $('.section-title').first().offset().top;
          }
          $('.on-page-menu').css('top',offsetTop + 'px');
          // Smooth scrolling to sections
          $('.on-page-menu_link').click(function(e){
            e.preventDefault();
            var id = $(this).data('id');
            $('html, body').animate({
              scrollTop: $('#' + id).offset().top - 150
            }, 800);
          });

          // Set active state on load
          $('.on-page-menu').find('.on-page-menu_link').first().addClass('active');

          // Define variable
          var oversized = '';

          if($(window).width() >= 1680) {
            oversized = $('.js-oversized-full');
          } else {
            oversized = $('.js-oversized');
          }
          
          // Loop through to check if there are any oversized items BEFORE the menu.
          oversized.each(function(i) {
            if ($(this).offset().top < $('.on-page-menu').offset().top) {
              $(this).removeClass('js-oversized');
            }
          });

          var isSticky = false;
          var originalPosition = $('.on-page-menu').offset().top;
          // Scrolling behaviour
          $( window ).scroll(function(){
            // Make menu sticky
            if($(window).scrollTop() > $('.on-page-menu').offset().top - 150){
              if(isSticky !== true) {
                $('.on-page-menu').addClass('sticky');
                isSticky = true;
              }
            } else if ($(window).scrollTop() <= originalPosition - 150) {
              if(isSticky) {
                $('.on-page-menu').removeClass('sticky');
                isSticky = false;
              }
            }

            // Redefine on scroll in case any is removed
            if($(window).width() >= 1680) {
              oversized = $('.js-oversized-full');
            } else {
              oversized = $('.js-oversized');
            }

            // Loop through on scroll and set classes accordingly
            oversized.each(function(i) {
              var elHeight = $(this).height(),
                  sideMenu = $('.on-page-menu'),
                  sideMenuHeight = sideMenu.height(),
                  offsetAfterEl = 25;

              // If near an fullwidth element, add .is-hidden
              if (sideMenu.offset().top > ($(this).offset().top - sideMenuHeight) &&
                  sideMenu.offset().top < $(this).offset().top + elHeight + offsetAfterEl) {
                sideMenu.addClass('is-hidden');
              }

              // If scrolled over an fullwidth element, remove .is-hidden
              else if (sideMenu.offset().top > $(this).offset().top + elHeight + offsetAfterEl) {
                sideMenu.removeClass('is-hidden');
              } 

              // Make sure the menu is always shown when scroll to top.
              if(sideMenu.offset().top < oversized.first().offset().top - sideMenuHeight) {
                sideMenu.removeClass('is-hidden');
              }
            });


            // Set active section in menu, $element variable outside of loop gets overwritten so only one section is set as active
            var $element = null;
            $('.section-title').each(function(index){
              if($(window).scrollTop() + 300 > $(this).offset().top ){
                $element = $('.on-page-menu_link').eq(index);
              }
            });
            if($element && !$element.hasClass('active')){
              $('.on-page-menu_link').removeClass('active');
              $element.addClass('active');
            }
          });
        });
        }


// >>>>> Scroll hijack
        if($('body').is(".single-cases, .page-template-page-service, .page-template-page-meet-the-team, .page-template-page-vacancies")){
          // Variable to keep track of amount scrolled
          var totalScroll = 0;
          // Delays the start of the text coming into view
          // EDITABLE FOR ANIMATION FINETUNING
          var delayFactor = 0.3;
          // The speed at which the text fades out, 1 means it takes the full duration, 2 is twice as fast, etc.
          // EDITABLE FOR ANIMATION FINETUNING
          var fadeOutSpeed = 3;

          var scrollAnimation = function(){

                // The text that should appear
                var $text = $('.page-header_text');
                // The disappearing lines
                var $lines = $('.header-lines').children();
                // Event handler (Uses wheel instead of mousehweel for crossbrowser compatibility

                $('.page-header').on('wheel touchstart ', function(e){
                  // Prevent scrolling
                    e.preventDefault();
                    // Amount scrolled in this event
                    var deltaY;

                    if ( e.originalEvent.deltaY) {
                      deltaY = e.originalEvent.deltaY;
                    } else if ($('body').hasClass('has-ended')) {
                          $('.page-header').bind('touchstart');
                          $('body').removeClass('has-ended');
                          $lines.first().css({'transform': 'translateX(' + 0 + '%)', 'transition': 'transform 1000ms ease'});
                          $lines.last().css({'transform': 'translateX(-' + 0 + '%)', 'transition': 'transform 1000ms ease'});
                          // Translates the new text into view
                          $text.css({'transform': 'translateY(' + ($lines.first().offset().top ) + 'px)', 'transition': 'transform 1000ms ease'});
                          // Fade in title
                          $('.page-header_title, .page-header_pre-title').css({'opacity': 1, 'transition': 'opacity 800ms ease'});
                          // Move background to the left
                          $('.page-header.opacity .page-header-overlay').css({'opacity': 0, 'transition': 'opacity 800ms ease'});
                    } else {
                      var swipe = e.originalEvent.touches,
                          start = swipe[0].pageY;
                      $(this).on('touchmove', function(e) {
                          var contact = e.originalEvent.touches,
                          end = contact[0].pageY,
                          distance = end-start;
                          var deltaY = distance;
    
                          if (distance < -10) {
                            $('.page-header').unbind('touchstart');
                            $('body').addClass('has-ended');
                            $lines.first().css({'transform': 'translateX(-' + 100 + '%)', 'transition': 'transform 1000ms ease'});
                            $lines.last().css({'transform': 'translateX(' + 100 + '%)', 'transition': 'transform 1000ms ease'});
                            // Translates the new text into view
                            $text.css({'transform': 'translateY(-' + (($(window).height() / 2) + ($text.height() * 0.75) ) + 'px)', 'transition': 'transform 1000ms ease'});
                            // Fade out title
                            $('.page-header_title, .page-header_pre-title').css({'opacity': 0, 'transition': 'opacity 800ms ease'});
                            // Move background to the left
                            $('.page-header.opacity .page-header-overlay').css({'opacity': 0.7, 'transition': 'opacity 800ms ease'});
                          }
                      })
                      .one('touchend', function() {
                          $(this).off('touchmove touchend');
                      });
                    }

                    // Amount scrolled
                    totalScroll = totalScroll + deltaY;
                    if (totalScroll < 0){
                      totalScroll = 0;
                    }
                    // Extra speed needed for the text movement to catch up with the lines movement, depends on the delay
                    var extraSpeed = 1 / (1 - delayFactor);
                    // Max amount that the lines will move to the sides [px]
                    var maxTranslateX = $(window).width() / 2;
                    if (totalScroll > maxTranslateX){
                      totalScroll = maxTranslateX;
                    }
                    // Total animation progress, 0 is start, 1 is end
                    var progress = totalScroll / maxTranslateX;
                    // Max amount that text will move up: the amount that the text needs to move to be centered [px]
                    var maxTranslateY = $(window).height() / 2 + $text.height() / 2;
                    // Amount that text is moved up, based on delay and the amount that the text needs to move to be centered [px]
                    var translateY = Math.max(0, maxTranslateY * (progress - delayFactor)  * extraSpeed);
                    // Move lines outward
                    $lines.first().css('transform','translateX(-' + totalScroll / 1.2 + 'px)');
                    $lines.last().css('transform','translateX(' + totalScroll / 1.2 + 'px)');
                    // Translates the new text into view
                    $text.css('transform','translateY(-' + translateY + 'px)');
                    // Fade out title
                    $('.page-header_title, .page-header_pre-title').css('opacity', (1 - progress * fadeOutSpeed));
                    $('.page-header.opacity .page-header_text').css({'opacity': 1, 'transition': 'opacity 800ms ease'});

                    // Move background to the left
                    $('.page-header.opacity .page-header-overlay').css('opacity', progress * 0.7);
                    $('.page-header.left').css('background-position', -$(window).width() / 2 * progress + 'px, center');
                    $('.page-header.right').css('background-position', $(window).width() / 2 * progress + 'px, center');
                    // Unbind when animation is done
                    if ( progress === 1 ) {
                      $('.page-header').unbind('wheel touchstart');
                    }
                });
              };
          scrollAnimation();
          $( window ).scroll(function(){
            // Rebind, to enable reversal of animation when scrolling up at top of page
            if( $(window).scrollTop() <= 0 ){
              scrollAnimation();
            }
          });
        }



// >>>>> Fade menu items in one by one

        $('.menu-toggle').click(function(){
          $('body').toggleClass('menu-open');
          // $( 'menu-item-has-children' ).find('ul').first().children().css('style','');
        });

        $('.js-mobile-dropdown-toggler').on('click', function(e) {
          e.preventDefault();

          if ($(this).hasClass('is-open')) {
            $(this).parents('.menu-list_item').find('.js-menu-drop').slideToggle();
            $(this).parents('.menu-list_item').find('.js-mobile-dropdown-icon').removeClass('is-open');
            $(this).removeClass('is-open');
          } else {
            $(this).parents('.menu-list_item').find('.js-menu-drop').slideToggle();
            $(this).parents('.menu-list_item').find('.js-mobile-dropdown-icon').addClass('is-open');
            $(this).addClass('is-open');
          }
        });

        var navButtonMargin = 30;

        function getNavButtonMargin() {
          if(window.matchMedia("(min-width: 1440px)").matches) {
            navButtonMargin = 30;
          } else {
            navButtonMargin = 15;
          }
        }
        window.addEventListener('resize', getNavButtonMargin, false);

        function closeDropdown() {
          $('.menu-drop').removeClass('is-open');
          $('.js-has-dropdown').removeClass('is-open');
        }

        $(document).on('click', '.js-has-dropdown', function (event) {
          event.preventDefault();

          if ($(this).hasClass('is-open')) {
            closeDropdown();
          } else {
            var getParentWidth = $(this).parent().outerWidth();
            var getNextItems = $(this).parent().nextAll();
            var getNextItemsWidth = 0;

            closeDropdown();

            getNextItems.each(function() {
              if (!$(this).hasClass('menu-button')) {
                getNextItemsWidth = getNextItemsWidth + $(this).width();
              }
            });

            $(this).parent().find('.menu-drop').addClass('is-open').css('width', getNextItemsWidth + getParentWidth + navButtonMargin);
            $(this).addClass('is-open');
          }
        });

        $(window).on('resize', closeDropdown);


// >>>>> Line animation when scrolling down
        // animation duration in pixels
        var duration = 300;
        var lineHeight = $( '.scroll-down' ).find('.line').height();
        var lineTop = $( '.scroll-down' ).find('.line').css('top');
        scrollDown = function(){
          $('.scroll-down').each(function(index) {
            var pos = $(window).scrollTop() + $(window).height() - ($( this ).offset().top + duration);
            if ( pos > 0 ){
              $( this ).find('.line').css('transform', 'scaleY(' + (1 + (pos / duration)) + ')' );
              $( this ).css('transform', 'translateY(-' + (pos / duration) * lineHeight / 2 + 'px)' );
              $( this ).css('opacity',(1 - Math.min(pos, duration)/duration));
            } else {
              $( this ).css('opacity',1);
              $( this ).find('.line').css('transform','');
              $( this ).css('transform','');
              $( this ).find('.line')[0].setAttribute("height","");
            }
          });
        };
        $( window ).scroll(scrollDown);
	    var swiper = [];
	    $('.swiper-container-gallery').each(function(index){
	
	        var $el = $(this);
	
			swiper[index] = $el.swiper({
	            //Your options here:
	            mode:'horizontal',
	            pagination: $el.find('.js-swiper-pagination')[0],
	            loop: true,
				onSlideChangeEnd: function (el) {
					$el.find('.js-page-of .js-page').html(el.realIndex + 1);			
					$el.find('.js-page-of .js-total').html(el.slides.length - 2);
					// when swiper is in loop mode, total length doesn't work. Substracting 2 does the trick.
				}
	        });
			

	        $el.find('.js-swiper-button-prev').on('click', function(e){
		        e.preventDefault();
	            swiper[index].slidePrev();
	        });
	
	        $el.find('.js-swiper-button-next').on('click', function(e){
		        e.preventDefault();
	            swiper[index].slideNext();
	        });
	    });
	    
        var swiperCasesImages = new Swiper ('.swiper-container--cases_images', {
          // Navigation arrows
          speed: 800,
          loop: true
        });
        var swiperCasesText = new Swiper ('.swiper-container--cases_text', {
			// Navigation arrows
			speed: 800,
			nextButton: '.home_swiper-button-next',
			prevButton: '.home_swiper-button-prev',
	        effect: 'fade',
            loop: true,
	        fade: {
	          crossFade: true
	        },
          onSlideChangeEnd: function (el) {
            $('.swiper-container--cases_text .page-of .page').html(el.realIndex + 1);
            $('.swiper-container--cases_text .page-of .total').html(el.slides.length - 2);
			// when swiper is in loop mode, total length doesn't work. Substracting 2 does the trick.
          }
        });
        
        //connect both swipers
		  swiperCasesText.params.control = swiperCasesImages;
		  swiperCasesImages.params.control = swiperCasesText;

      // ==========================================================================
      // Modal Photo Handler
      // ==========================================================================
/*
      var getProfileImage = '';
      if($('body').hasClass('about-us') || 
        $('body').hasClass('interim-support') || 
        $('body').hasClass('single-team')) {
        var getInTouchToggle = $('.js-request-cv-toggle');
        getInTouchToggle.on('click', function() {
          if ($(this).hasClass('js-request-cv-normal')) {
            getProfileImage = $(this).parents('.team_member-header').find('.team_member-header-picture').attr('src');
            $('.js-request-cv .js-modal-right').css('background-image', 'url(' + getProfileImage + ')');
          } else {          
            getProfileImage = $(this).parents('.js-team-member').find('.profile-card--picture').attr('src');
            $('.js-request-cv .js-modal-right').css('background-image', 'url(' + getProfileImage + ')');
          }
        });
      }
*/

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
      //JavaScript to be fired on the home page
      $('.slide-text-wrapper').fadeOut(0);
      $('.what-we-do_swiper-container .button').fadeOut(0);
      var $scrolling = $( '.scrolling' );

      function scrollSticky() {
        var $section = $('.home_section_type');
        var $sectionOne = $section.eq(0);
        var $sectionTwo = $section.eq(1);
        var scroll = $(window).scrollTop();
        var offsetOne = $sectionOne.offset().top;
        var offsetTwo = $sectionTwo.offset().top;
        var windowHeight = window.innerHeight;
        var lineHeight = $('.scrolling-line').height();

        if( scroll - offsetOne + windowHeight / 2 - $sectionOne[0].clientHeight / 2 > 0 && scroll - offsetTwo + windowHeight / 2 - $sectionTwo[0].clientHeight / 2 < 0){
          $('.scrolling').addClass('sticky');
          $('.scrolling').removeClass('hidden');
          $('.non-scrolling-text').addClass('hidden');
          $('.non-scrolling-line').addClass('hidden');
        } else if (scroll - offsetTwo + windowHeight / 2 - $sectionTwo[0].clientHeight / 2 > 0){
          $('.scrolling').addClass('hidden');
          $('.non-scrolling-text').removeClass('hidden');
          $('.non-scrolling-line').removeClass('hidden');
        } else {
          $('.scrolling').removeClass('sticky');
          $('.scrolling').removeClass('hidden');
          $('.non-scrolling-text').addClass('hidden');
          $('.non-scrolling-line').addClass('hidden');
        }
      }
      scrollSticky();
      $( window ).scroll(scrollSticky);
      $( window ).resize(scrollSticky);
    

      var homeOverlay = $('.home-animation .about-video_bg');
  		var textLeft = ['Tech', 'Profit', 'Now'];
  		var textRight = ['Market', 'Value', 'Next'];
  		var textCenter = ['What if standard marketing tools just don\'t cut it anymore', 'What if value is not only measured by profit'];
      var bgColor = ['#6E40CC', '#E5E5E6', '#96D7CD'];
  		var iLeft = 0;
  		var iRight = 0;
      var iBg = 0;
      var iCenter = -1;
      var isFirst = true;
      var iterator = 0;
      var animEasing = 'easeInOutQuart';
      /* jshint ignore:start */
      function runAnimationFinalLine() {
        anime({
          targets: '.anime_center_line',
          scaleX: [
            {value: 4, duration: 1000},
            {value: 4, duration: 200},
            {value: 1, duration: 500},
          ],
          easing: animEasing
        });
      }

    	function runAnimationLine() {
    		anime({
    			targets: '.anime_center_line',
    			scaleX: [
    				{value: 4, duration: 1000},
    				{value: 4, duration: 4000},
    				{value: 1, duration: 1000},
      	    {value: 1, duration: 1000}
      		],
      		  easing: animEasing,
      	    complete: function() {
        		if (iterator === 1) {
        		  runAnimationFinalLine();
        		} else {
        		  runAnimationLine();
        		}
      		}
      	});
    	}

      function runAnimationBg(){
        if (iBg < bgColor.length - 1) {
          iBg++;
        } else {
          iBg = 0;
        }
      }

      function runAnimationLeft(){
        if (iLeft < textLeft.length - 1) {
          animationLeft();
          iLeft++;
        } else {
          animationLeft();
          iLeft = 0;
        }
      }


      function runAnimationRight(){
        if (iRight < textRight.length - 1) {
          animationRight();
          iRight++;
        } else {
          animationRight();
          iRight = 0;
        }
      }

      function runAnimationText(){
        anime({
          targets: '.anime_text_wrap',
          translateY: [
            {value: ["-170%", "-170%"], duration: 500},
            {value: 0, duration: 500},
            {value: 0, duration: 4000},
            {value: "-170%", duration: 500},
            {value: "-170%", duration: 1500}
          ],
          easing: animEasing,
          begin: function() {
            runAnimationBg();
            homeOverlay.css('background-color', bgColor[iBg]);
          },
          complete: function() {
            iterator++;
            if (iterator > 1) {
            } else {
              runAnimationCenter();
              $('.anime_text_wrap').text(textCenter[iCenter]);
            }
          }
        });
      }

      function runAnimationCenter(){
        if (iCenter < textCenter.length - 1) {
          runAnimationText();
          iCenter++;
        } else {
          runAnimationText();
          iCenter = 0;
        }
      }

  		function animationLeft() {
  			anime({
  				targets: '.anime_left_text',
  				translateX: [
  					{ value: "-34%", duration: 1000 },
  					{ value: "-34%", duration: 4000 },
  					{ value: 0, duration: 1000 },
            { value: 0, duration: 1000 }
  				],
  				easing: animEasing,
          begin: function() {
            if(isFirst === true) {
              setTimeout(function() {
                $('.anime_left_text').text(textLeft[1]);
              }, 100);
            }
          },
  				complete: function(anim) {
            if(isFirst === true) {
              runAnimationLeft();
              setTimeout(function() {
                $('.anime_left_text').text(textLeft[2]);
              }, 100);
            }

            if (iterator === 1) {
              animationFinalLeft();
              setTimeout(function() {
                $('.anime_left_text').text(textLeft[0]);
              }, 100);
            } else {
              if(isFirst === false) {
      					runAnimationLeft();
                setTimeout(function() {
        					$('.anime_left_text').text(textLeft[iLeft]);
                }, 100);
              }
            }
  				}
  			});
  		}

  		function animationRight() {
  			anime({
  				targets: '.anime_right_text',
  				translateX: [
  					{ value: "34%", duration: 1000 },
  					{ value: "34%", duration: 4000 },
  					{ value: 0, duration: 1000 },
            { value: 0, duration: 1000 }
  				],
          easing: animEasing,
          begin: function() {
            if(isFirst === true) {
              setTimeout(function() {
                $('.anime_right_text').text(textRight[1]);
              }, 100);
            }
          },
          complete: function(anim2) {
            if(isFirst === true) {
              runAnimationRight();
              setTimeout(function() {
                $('.anime_right_text').text(textRight[2]);
              }, 100);
            }

            if (iterator === 1) {
              animationFinalRight();
              setTimeout(function() {
                $('.anime_right_text').text(textRight[0]);
              }, 100);
            } else {
              if (isFirst === false) {           
                runAnimationRight();
                setTimeout(function() {
                  $('.anime_right_text').text(textRight[iRight]);
                }, 100);
              }
            }

            isFirst = false;
          }
        });
      }

      function animationFinalLeft() {
        anime({
          targets: '.anime_left_text',
          translateX: [
            { value: "-34%", duration: 1000 },
            { value: "-34%", duration: 200 },
            { value: 0, duration: 500 },
          ],
          easing: animEasing,
          begin: function() {
            runAnimationBg();
            homeOverlay.css('background-color', bgColor[0]);
          },
          complete: function() {
            iterator = 0;
            setTimeout(function() {
              runAnimationLeft();
              runAnimationRight();
              runAnimationLine();
              runAnimationText();
              iRight = 1;
              iLeft = 1;
              iCenter = 1;
              setTimeout(function() {
                $('.anime_left_text').text(textLeft[iLeft]);
              }, 100);
              setTimeout(function() {
                $('.anime_right_text').text(textRight[iRight]);
              }, 100);
              $('.anime_text_wrap').text('What if value is not only measured by profit');
            }, 3000);
          }
        });
      }

      function animationFinalRight() {
        anime({
          targets: '.anime_right_text',
          translateX: [
            { value: "34%", duration: 1000 },
            { value: "34%", duration: 200 },
            { value: 0, duration: 500 },
          ],
          easing: animEasing
        });
      }

      /* jshint ignore:end */

      setTimeout(function() {
        runAnimationLeft();
        runAnimationRight();
        runAnimationLine();
        runAnimationText();
      }, 3500);


      var homeVideo = videojs('video-home', {
        autoplay: true,
        preload: 'auto',
        loop: true,
        muted: true,
        controls: false
      });

      homeVideo.play();
      
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
        
        $('.what-we-do_swiper-container .button').eq(0).fadeIn(300);
        var servicesSwiper = new Swiper ('.what-we-do_swiper-container', {
          // Navigation arrows
          speed: 800,
          nextButton: '.what-we-do_swiper-button-next',
          prevButton: '.what-we-do_swiper-button-prev',
          onSlideChangeStart: function (servicesSwiper) {
            var $slideText = $('.what-we-do_swiper-container .button').eq(servicesSwiper.previousIndex);
            var $upcomingSlideText = $('.what-we-do_swiper-container .button').eq(servicesSwiper.activeIndex);
            $slideText.hide(0, function(){
              $upcomingSlideText.show(0);
            });
          },
          onSlideChangeEnd: function (servicesSwiper) {
            $('.what-we-do_page-of .page').html(servicesSwiper.activeIndex + 1);
            $('.what-we-do_page-of .total').html(servicesSwiper.slides.length);
          }
        });
        // Client logo swiper // 
          var clientsSwiper = new Swiper ('.swiper-container--clients', {
            direction: 'horizontal',
            pagination: '.swiper-container-pagination--clients',
            paginationClickable: true,
            autoplay: 2500,
            speed: 1000,
            autoplayDisableOnInteraction: false,
            loop: true
        });

      }
    },
    // About us page, note the change from about-us to about_us.
    'page_template_page_about': {
      init: function() {
        // JavaScript to be fired on the about us page
        var aboutVideoToggle = $('.js-play-about-video');

        var aboutVideo = videojs('video-about', {
          autoplay: true,
          preload: false,
          loop: true,
          muted: true,
          controls: false
        });

        aboutVideo.on('fullscreenchange', function() {
          if(aboutVideo.isFullscreen()) {
            aboutVideo.currentTime(0);
            aboutVideo.play();
            aboutVideo.muted(false);
            aboutVideo.controls(true);
          } else {
            aboutVideo.muted(true);
            aboutVideo.controls(false);
          }
        });

        aboutVideoToggle.on('click', function(e) {
          e.preventDefault();
          if(aboutVideo.isFullscreen() !== true) {
            aboutVideo.requestFullscreen();
          }
        });
      }
    },
    // News Page AJAX Load More
    'page_template_page_news': {
      init: function() {
        // JavaScript to be fired on the news page
        var newsItems = $('.js-news-single-item').length;
        var allNewsItems = $('.js-news-list').data('all-posts');

        if (newsItems === allNewsItems) {
          $('.js-loadmore').hide();
        }

        // News AJAX Load More
        var $content = $('.ajax_posts');
        var $loader = $('.js-loadmore');
        var $loaderText = $('.js-loadmore-text');
        var $main = $('.js-news-list');
        var $filtered = $main.data('filtered-posts');

        function load_ajax_posts() {
          var offset = $main.find('.js-news-item').length;
          var ppp = 100;
          console.log('data', offset, ppp, $filtered);
          if (!($loader.hasClass('post_loading_loader') || $loader.hasClass('post_no_more_posts'))) {
            $.ajax({
              type: 'POST',
              dataType: 'html',
              url: ttm_ajax_load_more.ajax_url,
              data: {
                'ppp': ppp,
                'post_type': 'news',
                'offset': offset,
                'filtered': $filtered,
                'action': 'ttm_more_post_ajax'
              },
              beforeSend: function () {
                $loader.addClass('is-loading');
                $loaderText.html(ttm_ajax_load_more.loading);
              },
              success: function (data) {
                var $data = $(data);
                console.log('DATA', data);
                if ($data.length) {
                  console.log('success');
                  var $newElements = $data.css({ opacity: 0 });
                  $content.append($newElements);

                  $newElements.animate({ opacity: 1 });

                  $('html,body').delay(500).animate({
                    scrollTop: $newElements.offset().top - 200
                  }, 600);

                  $loader.removeClass('is-loading').addClass('is-empty');
                  $loaderText.html(ttm_ajax_load_more.noposts);
                } else {
                  $loader.removeClass('is-loading').addClass('is-empty');
                  $loaderText.html(ttm_ajax_load_more.noposts);
                }
              },
              error: function (jqXHR, textStatus, errorThrown) {
                $loader.html($.parseJSON(jqXHR.responseText) + ' :: ' + textStatus + ' :: ' + errorThrown);
                console.log(jqXHR);
              },
            });
          }

          return false;
        }

        $loader.on('click', load_ajax_posts);
      }
    },
  };


  /* Request CV Modal Handling
  /* ======================================================================== */

    var requestCv = $('.js-request-cv'),
      requestCvToggle = $('.js-request-cv-toggle'),
      requestCvToggleClose = $('.js-request-cv-toggle-close'),
/*
      requestCvNormal = $('.js-request-cv-normal'),
      requestCvTouch = $('.js-request-cv-touch'),
*/
      requestCvOpenState = false;

    function openRequestCvModal() {
      requestCv.removeClass('is-closed').addClass('is-open');
      requestCvOpenState = true;
      $('body').addClass('modal-is-visible');
      if($('body').hasClass('is-ios')) {
        $('.modal').css({'top': $(window).scrollTop()});
      }
    }

    function closeRequestCvModal () {
      requestCv.removeClass('is-open').addClass('is-closed');
      requestCvOpenState = false;
      $('body').removeClass('modal-is-visible');
		// contactdetails.removeClass('is-closed').addClass('is-open');
		// contactform.removeClass('is-open').addClass('is-closed');
    }

    requestCvToggle.on('click', function (e) {
	  e.preventDefault();
      openRequestCvModal();
    });

    requestCvToggleClose.on('click', function (e) {
	  e.preventDefault();
      closeRequestCvModal();
//       requestCv.find('.modal_switch_content').removeClass('is-visible').addClass('is-hidden');
    });

/*
    requestCvNormal.on('click', function (e) {
		requestCv.find('.modal_switch_content.modal_normal').addClass('is-visible').removeClass('is-hidden');
	});
    requestCvTouch.on('click', function (e) {
		requestCv.find('.modal_switch_content.modal_touch').addClass('is-visible').removeClass('is-hidden');
	});
*/

  /* Request Expertise Modal Handling
  /* ======================================================================== */

    var requestExpertise = $('.js-request-expertise'),
      requestExpertiseToggle = $('.js-request-expertise-toggle'),
      requestExpertiseToggleClose = $('.js-request-expertise-toggle-close'),
      requestExpertiseOpenState = false; 

    function openRequestExpertiseModal() {
      requestExpertise.removeClass('is-closed').addClass('is-open');
      requestExpertiseOpenState = true;
      $('body').addClass('modal-is-visible');
      if($('body').hasClass('is-ios')) {
        $('.modal').css({'top': $(window).scrollTop()});
      }
    }

    function closeRequestExpertiseModal() {
      requestExpertise.removeClass('is-open').addClass('is-closed');
      requestExpertiseOpenState = false; 
      $('body').removeClass('modal-is-visible');
    }

    requestExpertiseToggle.on('click', function (e) {
    e.preventDefault();
      openRequestExpertiseModal();
    });

    requestExpertiseToggleClose.on('click', function (e) {
    e.preventDefault();
      closeRequestExpertiseModal();
    });

  /* Request Full Case Modal Handling
  /* ======================================================================== */

    var requestFullCase = $('.js-request-full-case'),
      requestFullCaseToggle = $('.js-request-full-case-toggle'),
      requestFullCaseToggleClose = $('.js-request-full-case-toggle-close'),
      requestFullCaseClient = $('.js-request-full-case-client'),
      requestFullCaseNormal = $('.js-request-full-case-normal'),
      requestFullCaseOpenState = false;

    function openRequestFullCaseModal() {
      requestFullCase.removeClass('is-closed').addClass('is-open');
      requestFullCaseOpenState = true;
      $('body').addClass('modal-is-visible');
      if($('body').hasClass('is-ios')) {
        $('.modal').css({'top': $(window).scrollTop()});
      }
    }

    function closeRequestFullCaseModal () {
      requestFullCase.removeClass('is-open').addClass('is-closed');
      requestFullCaseOpenState = false;
      $('body').removeClass('modal-is-visible');
    }

    requestFullCaseToggle.on('click', function (e) {
	  e.preventDefault();
      openRequestFullCaseModal();
    });

    requestFullCaseToggleClose.on('click', function (e) {
	  e.preventDefault();
      closeRequestFullCaseModal();
      requestFullCase.find('.modal_switch_content').removeClass('is-visible').addClass('is-hidden');
    });

    requestFullCaseClient.on('click', function (e) {
		requestFullCase.find('.modal_switch_content.modal_client').addClass('is-visible').removeClass('is-hidden');
	});
    requestFullCaseNormal.on('click', function (e) {
		requestFullCase.find('.modal_switch_content.modal_normal').addClass('is-visible').removeClass('is-hidden');
	});

  /* Contact us Modal Handling
  /* ======================================================================== */

    var contact = $('.js-contact'),
      contactToggle = $('.js-contact-toggle'),
      contactToggleClose = $('.js-contact-toggle-close'),
      contactOpenState = false;

    function openContactModal() {
      contact.removeClass('is-closed').addClass('is-open');
      contactOpenState = true;
      $('body').addClass('modal-is-visible');
      if($('body').hasClass('is-ios')) {
        $('.modal').css({'top': $(window).scrollTop()});
      }
    }

    function closeContactModal () {
      contact.removeClass('is-open').addClass('is-closed');
      contactOpenState = false;
      $('body').removeClass('modal-is-visible');

     }

    contactToggle.on('click', function (e) {
	  e.preventDefault();
      openContactModal();
    });

    contactToggleClose.on('click', function (e) {
	  e.preventDefault();
      closeContactModal();
    });

    /* Contact Form  Handling
    /* ======================================================================== */

    var contactdetails = $('.js-contactdetails'),
    	contactform = $('.js-contactform'),
        contactformToggle = $('.js-contactform-toggle');

    function openContactFormModal() {
		contactdetails.removeClass('is-open').addClass('is-closed');
		contactform.removeClass('is-closed').addClass('is-open');
    }

    contactformToggle.on('click', function (e) {
    e.preventDefault();
		openContactFormModal();
    });

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
